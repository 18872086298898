<template>
  <div>    
    <div class="vx-row justify-center">
      <div class="vx-col xl:w-1/2 lg:w-2/3 w-full  mt-8">
        <!-- Call Tracking Integration -->
        <vx-card>
          <div class="card-title">
            <h2>Call Tracking Integration</h2>
          </div> 
          <div class="analytics-integration">
            <div class="vx-row">
              <div class="vx-col w-full"><h3>Google Analytics Integration</h3></div>
            </div>  
            <template>
              <div class="vx-row">
                <div class="vx-col md:w-1/2 w-full  mb-6">
                  <span class="label-name">GA Tracking Id</span>
                  <vs-input class="w-full" v-model="googleAnalyticsAccountId" />
                </div>
                <div class="vx-col md:w-1/2 w-full  mb-6">
                  <label class="label-name">Enable Integration</label>
                  <vs-switch color="success" v-model="googleAnalyticsIntegrationEnable">
                    <span slot="on">On</span>
                    <span slot="off">Off</span>
                  </vs-switch>
                </div>
              </div>
            </template>
            <div class="flex justify-end flex-wrap w-full mt-4">                
              <vs-button type="filled" class="sm:w-auto w-full" color="primary" @click="updateGoogleAnalyticsIntegration()">{{ LabelConstant.buttonLabelSaveGoogleAnalyticsIntegration }}</vs-button>
              <vs-button color="grey" type="border" class="grey-btn ml-0 mt-2 sm:mt-0 sm:ml-4 sm:w-auto w-full" @click="getIntegrationList()">{{ LabelConstant.buttonLabelReset }}</vs-button>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";

export default {
  components: {
    VxCard  
  },
  data() {
    return {
      googleAnalyticsAccountId: null,
      googleAnalyticsIntegrationEnable:true,
    }
  },
  created(){
    this.getIntegrationList()
  },
  methods:{
    //Get call tracking integration configuration
    getIntegrationList(){
      this.$vs.loading()
      this.axios.get("/ws/CallTrackIntegration/GetIntegrationList").then(response => {
        let data = response.data;
        this.googleAnalyticsAccountId = data.GoogleAnalyticsAccountId
        this.googleAnalyticsIntegrationEnable = data.GoogleAnalyticsEnable
				this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
      });
    },
    //Update call tracking integration configuration
    updateGoogleAnalyticsIntegration(){
      let updateGoogleAnalyticsIntegrationParams = {
        Integration_Account_Id: this.googleAnalyticsAccountId,
        IsActive: this.googleAnalyticsIntegrationEnable
      }

      this.$vs.loading()
      this.axios.post("/ws/CallTrackIntegration/UpdateGoogleAnalyticsIntegration", updateGoogleAnalyticsIntegrationParams).then(response => {
        let data = response.data;
        
        this.googleAnalyticsAccountId = data.GoogleAnalyticsAccountId
        this.googleAnalyticsIntegrationEnable = data.GoogleAnalyticsEnable
        this.$vs.notify({
          title:'Success',
          text: 'Your information has been updated successfully.',
          color: "success",
          iconPack: 'feather',
          icon:'icon-check',
          position: "top-right",
          time:4000
        });
				this.$vs.loading.close()
      }).catch(e => {
        this.$vs.loading.close()
        this.showError(e);
        this.$vs.notify({
          title:'Error',
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: 'feather',
          icon:'icon-alert-circle',
          position:"top-right",
          time:4000
        });
      });
    }
  }
}   
</script>
